.skeleton-loader {
    overflow: hidden !important;
    position: relative !important;
    border: unset !important;
    color: #f5f5f5 !important;
    background-color: #f5f5f5 !important;
    pointer-events: none !important;
    cursor: unset !important;
  
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 1) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
      animation: shimmer 1s infinite;
      content: '';
      -webkit-transform: translateX(-100%);
      -moz-transform: translateX(-100%);
      -ms-transform: translateX(-100%);
      -o-transform: translateX(-100%);
      -webkit-animation: shimmer 1.5s infinite;
    }
  
    @keyframes shimmer {
      100% {
        transform: translateX(100%);
        -webkit-transform: translateX(100%);
        -moz-transform: translateX(100%);
        -ms-transform: translateX(100%);
        -o-transform: translateX(100%);
      }
    }
  }
  
  .skeleton-loader>* {
    visibility: hidden !important;
  }
