html,
body {
  height: 100%;
  background-color: #FFF;
}

body {
  margin: 0;
  font-family: 'AkkuratLLWeb';
  font-feature-settings: 'dlig'on, 'ss01'on, 'ss02'on, 'ss03'on, 'ss05'on;
}

