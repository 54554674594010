@import '../helpers/variables';

.header-hidden.mat-mdc-tab-group {
	> .mat-mdc-tab-header {
		height: 0;
		overflow: hidden;
		border-width: 0px;
	}
}

.settings-title {
	font-size: 12pt;
	font-weight: 500;
	line-height: 24px;
	margin-bottom: 24px;
}

.settings-header {
    height: 64px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.settings-title-not-first {
	font-size: 16px;
	font-weight: 700;
	margin: 48px 0 24px 0;
}

.settings-title-not-first.subscriptions-margin {
	margin-top: 24px;
}

.settings-label-text {
	font-size: 14px;
	line-height: 24px;
}

.settings-green-icon {
	color: #64dc9a;
}

.settings-connection-icon {
	width: 48px !important;
	height: 48px !important;
	border-radius: 100px;

	display: flex !important;
	align-items: center;
	justify-content: center;
}

.settings-generic-bank-icon {
	background: rgba(0, 0, 0, 0.06);
	border-radius: 100px;
	width: 36px;
	height: 36px;
	font-size: 20px;

	display: flex;
	align-items: center;
	justify-content: center;
}

.settings-bank-tag-chip {
	background: rgba(0, 0, 0, 0.12) !important;
	font-weight: 400;
	font-size: 12px !important;
	max-height: 20px !important;
	min-height: 20px !important;
	line-height: 12px;
	opacity: 1 !important;
}

.settings-auth-form {
	.mat-mdc-form-field-flex {
		height: 48px;
		font-size: 14px;
		display: flex;
		align-items: center;
	}
	.mat-mdc-text-field-wrapper {
		padding-bottom: 8px;
	}
}

.settings-bank-tag-chip:not(:last-of-type) {
	margin-right: 8px;
}

.settings-grey-icon {
	color: rgba(0, 0, 0, 0.12);
}

.settings-checkout-stepper {
	.mat-stepper-horizontal-line:last-of-type {
		display: none;
	}

	.mat-step-header:nth-of-type(4) {
		display: none;
	}

	.mat-horizontal-stepper-header-container {
		border-bottom: 1px solid rgba(0, 0, 0, 0.16);
	}
}

.settings-icon-spinner {
	width: 24px;
	height: 24px;
	display: flex;
	justify-content: center;
}

.settings-checkout-stepper-after-confirm {
	.mat-horizontal-stepper-header-container {
		display: none;
	}
}

.invoice-download-button {
	color: rgba(0, 0, 0, 0.56);
	text-decoration: none;
}

.invoice-download-button:hover {
	cursor: pointer;
}

.invoice-status-html {
	width: 73px;
	height: 28px;
	background: rgba(0, 108, 226, 0.08);
	border: 1px solid rgba(0, 108, 226, 0.16);
	border-radius: 14px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	color: $primary;
}

.custom-menu {
	min-width: 240px !important;

	.red-FF5252 {
		color: #ff5252 !important;
	}
}

.subscriptions-page-container {
	margin-top: -30px;

	.subscriptions-page-header {
		padding-bottom: 19px;
		padding-top: 20px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.12);
		line-height: 24px;
		font-weight: 500;
		margin-top: -8px;
		top: 0px;
		left: 0px;
		position: sticky;
		background-color: white;
		z-index: 200;
		width: 100%;
	}
}

trovata-users app-paginated-table {
	td.mat-mdc-cell.mat-column-status,
	th.mat-mdc-header-cell.mat-column-status {
		min-width: 145px;
	}
}

.feature-permission-data-view {
	.mat-expansion-panel-body {
		padding: 0px;
	}

	.mat-expansion-panel.feature-panel {
		border-radius: 0px;
		box-shadow: none;

		.mat-expansion-panel-header {
			padding: 12px 0px;
		}
	}

	.permissions-toggle {
		.mat-button-toggle-label-content {
			line-height: 20px;
			padding: 4px 8px;
		}
	}

	.expansion-button {
		height: 32px;
		width: 32px;
		float: left;
		font-size: 20px;
		line-height: 20px;
		margin-right: 6px;

		.mat-button-wrapper,
		.mat-button-wrapper * {
			height: 24px;
			width: 24px;
			vertical-align: middle;
		}
	}
}

.new-user-group-stepper {
	.mat-step-optional {
		display: none;
	}
}

.mat-icon.feature-badge {
	font-size: 20px;
	height: 20px;
	width: 20px;
	vertical-align: middle;
}

trovata-manage-groups-dialog app-paginated-table mat-slide-toggle,
trovata-add-edit-user-dialog app-paginated-table mat-slide-toggle {
	margin-right: 50px;
}

.past-invoices-table {
	.paginated-table {
		.mat-column-downloadLink {
			width: 5%;
		}

		.mat-column-amount {
			width: 10%;
		}

		.mat-column-status {
			width: 5%;
		}
	}
}

.business-bordered-box:hover {
	cursor: pointer;
	background-color: rgba(0, 0, 0, 0.04);
}

.business-bordered-box-selected {
	background-color: rgba(0, 0, 0, 0.04);
	border: 2px solid $primary;
}

.business-bordered-non-selected {
	border: 1px solid rgba(0, 0, 0, 0.16);
}

.business-type-text {
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	color: $dark-primary-text;
}

.business-sub-text {
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
	color: rgba(0, 0, 0, 0.56);
	margin: 0;
}

.continue-button-container {
	position: relative;
}

.continue-button-spinner {
	position: absolute !important;
	top: 7px;
	right: 15px;
}

.title-container {
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
}

.sub-title-text {
	font-size: 14px;
	font-weight: 400;
	line-height: 24px;
}

mat-expansion-panel-header.data-sharing-panel-header > .mat-content {
	justify-content: space-between !important;
}

.accounts-resource-select {
	.balances-grouping-table {
		.paginated-table {
			min-width: 455px;
		}
	}
	.mat-column-compactLabel {
		width: 160px;
		max-width: 160px;
	}

	.mat-column-entityId {
		width: 150px;
		max-width: 150px;
	}
}


/***************************************************************************************************
************************************** HubSpot Form Styling ****************************************
***************************************************************************************************/

.hs-button {
	border: none;
	border-radius: 4px;
	margin: 0px;
}

.hubspot-input,
.hs-input {
	width: 100%;
	height: 55px;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.125;
	font-family: Roboto, 'Helvetica Neue', sans-serif;
	border: 1px solid #ced4da;
	border-radius: 5px;
	padding: 0px 0.75rem;
}

.hubspot-select {
	cursor: pointer;
	height: 55px;
	font-size: 1rem;
	font-weight: 400;
	line-height: 1.125;
	font-family: Roboto, 'Helvetica Neue', sans-serif;
	border: 1px solid #ced4da;
	border-radius: 5px;
	color: rgba(0, 0, 0, 0.56);
}

.hubspot-input-container,
.hubspot-select-container {
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: stretch;
	width: 100%;
}

.hubspot-input-container input:focus,
.hubspot-input-container input:hover,
.hubspot-input-container select:focus,
.hubspot-input-container select:hover,
.hubspot-input-container input.value-error,
.hubspot-select-container input:focus,
.hubspot-select-container input:hover,
.hubspot-select-container select:focus,
.hubspot-select-container select:hover,
.hubspot-select-container input.value-error {
	border: 1px solid $primary;
	outline: unset;
	outline-offset: unset;
}

.hubspot-input-container > label {
	top: 23px;
	left: 5px;
	position: absolute;
	color: rgba(0, 0, 0, 0.56);
	background-color: white;
	padding: 0px 5px 0px 5px;
	font-size: 1.1em;
	transition: 0.2s;
	pointer-events: none;
	z-index: 4;
}

.hubspot-select-container > label {
	top: 20px;
	left: 5px;
	position: absolute;
	color: rgba(0, 0, 0, 0.56);
	background-color: white;
	padding: 0px 5px 0px 5px;
	font-size: 1.1em;
	transition: 0.2s;
	pointer-events: none;
	z-index: 4;
}

.hubspot-input-container.label-animate > label,
.hubspot-select-container.label-animate > label {
	top: -10px;
	left: 16px;
	font-size: 0.8em;
}

.hubspot-input-container,
.hubspot-select-container,
.hubspot-input-container.label-animate,
.hubspot-select-container.label-animate {
	animation: none !important;
}

trovata-connect-a-bank-dialog fieldset,
trovata-onboard fieldset {
	max-width: unset !important;
}

trovata-connect-a-bank-dialog ul,
trovata-onboard ul {
	list-style: none !important;
	list-style-type: unset;
	margin-block-start: unset;
	margin-block-end: unset;
	margin-inline-start: unset;
	margin-inline-end: unset;
	padding-inline-start: unset;
	padding: 0 1em;
	font-size: 75%;
	color: #f44336;
	transform: translateY(-10px);
}

.settings-danger-button {
	background: rgba(255, 82, 82, 1);
	color: #ffffff;
}

trovata-create-edit-user-group-dialog .mat-horizontal-content-container {
	padding: unset;
}

trovata-create-edit-user-group-dialog .mat-step-icon-selected,
trovata-create-edit-user-group-dialog .mat-step-icon-state-edit {
	background-color: $primary !important;
}

trovata-create-edit-user-group-dialog .mat-step-header[aria-selected='true'] {
	background-color: rgba(0, 0, 0, 0.04);
}

trovata-review-user-group-changes .mat-expansion-panel-body {
	padding: 0 0 0 24px !important;
}

trovata-review-user-group-changes .mat-expansion-panel:not([class*='mat-elevation-z']) {
	box-shadow: unset;
}

trovata-review-user-group-changes .mat-expansion-panel:first-of-type {
	border-top-right-radius: unset;
	border-top-left-radius: unset;
}

trovata-review-user-group-changes .mat-expansion-panel {
	background: #f7f7f7;
}

trovata-review-user-group-changes .mat-expansion-panel-spacing {
	margin: unset;
}

trovata-review-user-group-changes trovata-review-user-group-permissions .mat-expansion-panel-header.mat-expanded {
	height: 48px;
}

trovata-review-user-group-changes .mat-expansion-panel-header {
	padding: 0 0 0 24px;
}

trovata-review-user-group-changes .changes-to-review-badge .mat-badge-content {
	color: rgba(0, 0, 0, 0.88);
	background: #ffb965;
}

trovata-review-user-group-changes .rejected-changes-to-review-badge .mat-badge-content {
	color: #fff;
	background: #d5255b;
}

trovata-review-user-group-changes .resource-or-user-chip {
	display: flex;
	padding: 4px 10px;
	align-items: center;
	border-radius: 12px;
	background: rgba(0, 0, 0, 0.12);
	margin: 0 12px 5px 0;
}
